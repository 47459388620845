import { useHistory } from 'react-router';
import { useCallback } from 'react';

export const useNav = () => {
  const history = useHistory();

  const go = useCallback(
    (url: string) => {
      history.push(url);
    },
    [history],
  );

  const goBack = useCallback(() => {
    if (history.length === 2 && history.location.pathname !== '/admin') {
      history.replace('/admin');
    } else {
      history.goBack();
    }
  }, [history]);

  return {
    go,
    goBack,
  };
};
