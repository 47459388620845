import firebase from 'firebase/app';
import { Epic } from 'redux-observable';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { authState } from 'rxfire/auth';

import { userProfileRefreshed, addError } from '../reducers/app';
import { IUser } from '../../models/user';

export const authEpic: Epic = () =>
  authState(firebase.auth()).pipe(
    map(firebaseUser => {
      if (!firebaseUser) {
        return null;
      }
      const user: IUser = {
        isAnonymous: firebaseUser.isAnonymous,
        id: firebaseUser.uid,
      };
      return user;
    }),
    map(user => {
      return userProfileRefreshed(user);
    }),
    catchError(error => {
      return of(addError(error));
    }),
  );
