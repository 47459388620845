import React, { useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonLoading,
  IonButtons,
  IonBackButton,
} from '@ionic/react';
import { RouteComponentProps } from 'react-router';

import { useLeads } from '../hooks/leads';

const LeadDetail: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: {
    params: { id },
  },
}) => {
  const { selectedLead, loadLeadDetail } = useLeads();

  useEffect(() => {
    loadLeadDetail(id);
  }, [loadLeadDetail, id]);

  const title = selectedLead ? `Lead: ${selectedLead.leadName}` : '';

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/leads" />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {selectedLead ? (
          <IonList>
            <IonItem>
              <IonLabel position="stacked">Name:</IonLabel>
              <div>{selectedLead.leadName}</div>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Received On:</IonLabel>
              <div>{selectedLead.createdDt.toLocaleString()}</div>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Assigned Agent:</IonLabel>
              <div>{selectedLead.assignedAgentName ?? 'Not Assigned'}</div>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Follow Up Email Sent</IonLabel>
              <div>{selectedLead.emailIds.length >= 2 ? 'Yes' : 'No'}</div>
            </IonItem>
          </IonList>
        ) : null}
        <IonLoading isOpen={!selectedLead} message={'Loading...'} />
      </IonContent>
    </IonPage>
  );
};

export default LeadDetail;
