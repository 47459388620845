import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonLoading,
  IonInput,
  IonToggle,
  IonButtons,
  IonBackButton,
  IonButton,
  IonFooter,
} from '@ionic/react';
import { RouteComponentProps } from 'react-router';

import { useAgents } from '../hooks/agents';
import { useNav } from '../hooks/nav';
import { IAgent } from '../models/agent';

import './AgentDetail.css';
import { LeadType } from '../models/lead-type';

const useForm = (agent: IAgent | null, id: string, isAdd: boolean) => {
  const [initialized, setInitialized] = useState(false);
  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const [agentRoles, setAgentRoles] = useState<{ [key in LeadType]: boolean }>({
    buyer: false,
    seller: false,
  });
  const prevId = useRef<string | null>(null);

  const handleNameChange = useCallback(
    ({ detail }: CustomEvent<{ value?: string | null }>) => {
      setName(detail.value ?? '');
    },
    [setName],
  );

  const handleEmailChange = useCallback(
    ({ detail }: CustomEvent<{ value?: string | null }>) => {
      setEmail(detail.value ?? '');
    },
    [setEmail],
  );

  const handlePhoneNumberChange = useCallback(
    ({ detail }: CustomEvent<{ value?: string | null }>) => {
      setPhoneNumber(detail.value ?? '');
    },
    [setPhoneNumber],
  );

  const handleIsActiveChange = useCallback(
    ({ detail: { checked } }: CustomEvent<{ checked: boolean }>) => {
      setIsActive(checked);
    },
    [setIsActive],
  );

  const handleAgentBuyerRoleChange = useCallback(
    ({ detail: { checked } }: CustomEvent<{ checked: boolean }>) => {
      setAgentRoles(state => {
        return {
          ...state,
          buyer: checked,
        };
      });
    },
    [setAgentRoles],
  );

  const handleAgentSellerRoleChange = useCallback(
    ({ detail: { checked } }: CustomEvent<{ checked: boolean }>) => {
      setAgentRoles(state => {
        return {
          ...state,
          seller: checked,
        };
      });
    },
    [setAgentRoles],
  );

  useEffect(() => {
    if (initialized) {
      return;
    }

    setInitialized(true);
    setName(agent?.name ?? '');
    setEmail(agent?.email ?? '');
    setPhoneNumber(agent?.phoneNumber ?? '');
    setIsActive(agent?.isActive ?? false);
    setAgentRoles(agent?.agentRoles ?? { buyer: false, seller: false });
  }, [agent, setName, setInitialized, initialized]);

  useEffect(() => {
    if (isAdd) {
      setInitialized(true);
    }
  }, [setInitialized, isAdd]);

  useEffect(() => {
    if (prevId.current !== id) {
      setInitialized(false);
      prevId.current = id;
    }
  }, [id, setInitialized]);

  return {
    name,
    email,
    phoneNumber,
    isActive,
    agentRoles,
    handleNameChange,
    handleEmailChange,
    handlePhoneNumberChange,
    handleIsActiveChange,
    handleAgentBuyerRoleChange,
    handleAgentSellerRoleChange,
  };
};

const AgentDetail: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: {
    params: { id },
  },
}) => {
  const isAdd = id === 'add';
  const {
    selectedAgent,
    loadAgentDetail,
    updateAgent,
    isSaving,
    hasSaved,
  } = useAgents();
  const { goBack } = useNav();
  const form = useForm(selectedAgent, id, isAdd);

  useEffect(() => {
    loadAgentDetail(id !== 'add' ? id : null);
  }, [loadAgentDetail, id]);

  const onSave = useCallback(() => {
    const updatedAgent: IAgent = {
      id: selectedAgent?.id,
      email: form.email,
      name: form.name,
      phoneNumber: form.phoneNumber,
      isActive: form.isActive,
      agentRoles: form.agentRoles,
    };
    updateAgent(updatedAgent);
  }, [updateAgent, form, selectedAgent]);

  const title = selectedAgent ? `Agent: ${selectedAgent.name}` : '';

  useEffect(() => {
    if (hasSaved) {
      goBack();
    }
  }, [hasSaved, goBack]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {selectedAgent || isAdd ? (
          <IonList>
            <IonItem>
              <IonLabel>Name:</IonLabel>
              <IonInput
                placeholder="Enter Name"
                value={form.name}
                onIonChange={form.handleNameChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Email:</IonLabel>
              <IonInput
                placeholder="Enter Email"
                value={form.email}
                type="email"
                onIonChange={form.handleEmailChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Phone Number:</IonLabel>
              <IonInput
                value={form.phoneNumber}
                type="tel"
                onIonChange={form.handlePhoneNumberChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Buyer</IonLabel>
              <IonToggle
                checked={form.agentRoles.buyer}
                onIonChange={form.handleAgentBuyerRoleChange}
              ></IonToggle>
            </IonItem>
            <IonItem>
              <IonLabel>Seller</IonLabel>
              <IonToggle
                checked={form.agentRoles.seller}
                onIonChange={form.handleAgentSellerRoleChange}
              ></IonToggle>
            </IonItem>
            <IonItem>
              <IonLabel>Active</IonLabel>
              <IonToggle
                checked={form.isActive}
                onIonChange={form.handleIsActiveChange}
              ></IonToggle>
            </IonItem>
          </IonList>
        ) : null}
        <IonLoading
          isOpen={isSaving || (!selectedAgent && !isAdd)}
          message={isSaving ? 'Saving...' : 'Loading...'}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={goBack}>Cancel</IonButton>
            <IonButton color="primary" onClick={onSave}>
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default AgentDetail;
