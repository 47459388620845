import { Epic, ofType, combineEpics } from 'redux-observable';
import { of, merge } from 'rxjs';
import {
  map,
  switchMap,
  catchError,
  distinctUntilKeyChanged,
  tap,
} from 'rxjs/operators';

import * as fromLeads from '../reducers/leads';
import * as fromAgents from '../reducers/agents';
import { addError } from '../reducers/app';
import * as db from '../../firebase';

export const loadLeads$: Epic = actions$ =>
  actions$.pipe(
    ofType(fromLeads.ActionType.LoadLeads),
    distinctUntilKeyChanged('type'),
    switchMap(() => {
      return merge(
        of(fromAgents.loadAgents()),
        db
          .observeLeadsCollection()
          .pipe(map(leads => fromLeads.leadsLoaded(leads))),
      );
    }),
    tap(foo => console.log(foo)),
    catchError(error => {
      return of(addError(error));
    }),
  );

export const loadLead$: Epic = actions$ =>
  actions$.pipe(
    ofType(fromLeads.ActionType.LoadLead),
    distinctUntilKeyChanged('type'),
    map(() => fromLeads.loadLeads()),
  );

export const leadsEpic = combineEpics(loadLeads$, loadLead$);
