import { combineReducers } from 'redux';

import { IRootState } from '../root-state';
import app from './app';
import agents from './agents';
import leads from './leads';

const reducers: { [key in keyof IRootState]: any } = {
  app,
  agents,
  leads,
};

export default combineReducers(reducers);
