import React, { useEffect, useCallback } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonFabButton,
  IonFab,
  IonIcon,
} from '@ionic/react';

import './Agents.css';

import { useAgents } from '../hooks/agents';
import { useNav } from '../hooks/nav';
import { add } from 'ionicons/icons';

const Agents: React.FC = () => {
  const { loadAgents, agents } = useAgents();
  const { go } = useNav();

  useEffect(() => {
    loadAgents();
  }, [loadAgents]);

  const goToAgentDetail = useCallback(
    (id: string) => {
      return () => {
        go(`/agents/${id}`);
      };
    },
    [go],
  );

  const goToAddAgent = useCallback(() => {
    go(`/agents/add`);
  }, [go]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Agents</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Agents</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {agents.map(({ id, name }) => {
            return (
              <IonItem key={id} detail button onClick={goToAgentDetail(id!)}>
                <IonLabel>{name}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={goToAddAgent}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default Agents;
