import { useCallback } from 'react';
import { auth } from '../firebase';
import { useDispatch } from 'react-redux';
import { addError } from '../store/reducers/app';
import { useNav } from './nav';

interface IAccountDetails {
  username: string;
  password: string;
}

export const useAuth = () => {
  const dispatch = useDispatch();
  const { go } = useNav();

  const isUserAlreadyAuthenticatedOrError = useCallback(() => {
    if (auth && auth.currentUser && auth.currentUser.isAnonymous === false) {
      dispatch(
        addError({
          message: 'User is already authenticated',
          code: 'auth/alread-authenticated',
        }),
      );
      return true;
    }
    return false;
  }, [dispatch]);

  const login = useCallback(
    async ({ username, password }: IAccountDetails) => {
      if (isUserAlreadyAuthenticatedOrError()) {
        return;
      }

      try {
        await auth.signInWithEmailAndPassword(username, password);
        go('/account/profile');
      } catch (error) {
        dispatch(addError(error));
      }
    },
    [dispatch, isUserAlreadyAuthenticatedOrError, go],
  );

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
    } catch (error) {
      dispatch(addError(error));
    }
  }, [dispatch]);

  return {
    login,
    logout,
  };
};
