import { createSelector } from 'reselect';

import { getRootState } from '../root-state';
import { IAgent } from '../../models/agent';

export interface IAgentsState {
  agents: {
    [key: string]: IAgent;
  };
  selectedAgentId: string | null;
  saving: boolean;
}

export enum ActionType {
  LoadAgents = 'LOAD_AGENTS',
  LoadAgent = 'LOAD_AGENT',
  AgentsLoaded = 'AGENTS_LOADED',
  SaveAgent = 'SAVE_AGENT',
  AgentSaved = 'AGENT_SAVED',
}

export function loadAgents(): { type: ActionType.LoadAgents } {
  return {
    type: ActionType.LoadAgents,
  };
}

export function loadAgent(
  agentId: string | null,
): { type: ActionType.LoadAgent; agentId: string | null } {
  return {
    agentId,
    type: ActionType.LoadAgent,
  };
}

export function agentsLoaded(
  agents: IAgent[],
): { type: ActionType.AgentsLoaded; agents: IAgent[] } {
  return {
    agents,
    type: ActionType.AgentsLoaded,
  };
}

export function saveAgent(
  agent: IAgent,
): { type: ActionType.SaveAgent; agent: IAgent } {
  return {
    agent,
    type: ActionType.SaveAgent,
  };
}

export function agentSaved(): { type: ActionType.AgentSaved } {
  return {
    type: ActionType.AgentSaved,
  };
}

export type AppActions =
  | ReturnType<typeof loadAgents>
  | ReturnType<typeof loadAgent>
  | ReturnType<typeof agentsLoaded>
  | ReturnType<typeof saveAgent>
  | ReturnType<typeof agentSaved>;

const app = (
  state: IAgentsState = {
    agents: {},
    selectedAgentId: null,
    saving: false,
  },
  action: AppActions,
): IAgentsState => {
  switch (action.type) {
    case ActionType.AgentsLoaded: {
      return {
        ...state,
        agents: action.agents.reduce((acc, agent) => {
          return { ...acc, [agent.id!]: agent };
        }, {}),
      };
    }
    case ActionType.LoadAgent: {
      return {
        ...state,
        selectedAgentId: action.agentId,
      };
    }
    case ActionType.SaveAgent: {
      return {
        ...state,
        saving: true,
      };
    }
    case ActionType.AgentSaved: {
      return {
        ...state,
        saving: false,
      };
    }
    default:
      return state;
  }
};

export default app;

export const getAgentsState = createSelector(
  getRootState,
  state => state.agents,
);

export const getAgentsObj = createSelector(
  getAgentsState,
  state => state.agents,
);

export const getAgents = createSelector(getAgentsObj, agents =>
  Object.values(agents),
);

export const getSelectedAgentId = createSelector(
  getAgentsState,
  state => state.selectedAgentId,
);

export const getSelectedAgent = createSelector(
  getAgentsObj,
  getSelectedAgentId,
  (agents, selectedAgentId) =>
    selectedAgentId ? agents[selectedAgentId] ?? null : null,
);

export const getIsSaving = createSelector(
  getAgentsState,
  state => state.saving,
);
