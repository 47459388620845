import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';

import * as fromApp from '../store/reducers/app';
import { auth } from '../firebase';

export const useApp = () => {
  const dispatch = useDispatch();
  const lastError = useSelector(fromApp.getLastError);
  const errorMessage = useSelector(fromApp.getErrorMessage);
  const user = useSelector(fromApp.getUser);
  const appLoaded = useSelector(fromApp.getAppLoaded);

  const signIn = useCallback(async () => {
    if (!user) {
      dispatch(fromApp.signIn());
      await auth.signInAnonymously();
    }
  }, [dispatch, user]);

  return {
    lastError,
    errorMessage,
    signIn,
    user,
    appLoaded,
  };
};
