import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { people, cog, person } from 'ionicons/icons';

import Agents from './pages/Agents';
import Leads from './pages/Leads';
import Settings from './pages/Settings';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import { useApp } from './hooks/app';
import AgentDetail from './pages/AgentDetail';
import LeadDetail from './pages/LeadDetail';

const PrivateRoute: React.FC<{ isLoggedIn: boolean } & RouteProps> = ({
  isLoggedIn,
  ...props
}) => (isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />);

const App: React.FC = () => {
  const { user, appLoaded } = useApp();

  return (
    <IonApp>
      {appLoaded ? (
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <PrivateRoute
                isLoggedIn={!!user}
                path="/agents"
                component={Agents}
                exact={true}
              />
              <PrivateRoute
                isLoggedIn={!!user}
                path="/agents/:id"
                component={AgentDetail}
                exact={true}
              />
              <PrivateRoute
                isLoggedIn={!!user}
                path="/leads"
                component={Leads}
                exact={true}
              />
              <PrivateRoute
                isLoggedIn={!!user}
                path="/leads/:id"
                component={LeadDetail}
                exact={true}
              />
              <PrivateRoute
                isLoggedIn={!!user}
                path="/settings"
                component={Settings}
              />
              <Route path="/login" component={Login} />
              <Route
                path="/"
                render={() => {
                  return <Redirect to={user ? '/agents' : '/login'} />;
                }}
                exact={true}
              />
              <Route
                path=""
                render={() => {
                  return <Redirect to="/" />;
                }}
              />
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="agents" href="/agents" hidden={!user}>
                <IonIcon icon={person} />
                <IonLabel>Agents</IonLabel>
              </IonTabButton>
              <IonTabButton tab="leads" href="/leads">
                <IonIcon icon={people} />
                <IonLabel>Leads</IonLabel>
              </IonTabButton>
              <IonTabButton tab="settings" href="/settings" hidden={!user}>
                <IonIcon icon={cog} />
                <IonLabel>Settings</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      ) : null}
    </IonApp>
  );
};

export default App;
