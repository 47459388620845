import { Epic, ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import {
  map,
  switchMap,
  catchError,
  distinctUntilKeyChanged,
} from 'rxjs/operators';

import * as fromAgents from '../reducers/agents';
import { addError } from '../reducers/app';
import * as db from '../../firebase';

export const loadAgents$: Epic = actions$ =>
  actions$.pipe(
    ofType(fromAgents.ActionType.LoadAgents),
    distinctUntilKeyChanged('type'),
    switchMap(() => {
      return db.observeAgentsCollection();
    }),
    map(agents => fromAgents.agentsLoaded(agents)),
    catchError(error => {
      return of(addError(error));
    }),
  );

export const loadAgent$: Epic = actions$ =>
  actions$.pipe(
    ofType(fromAgents.ActionType.LoadAgent),
    distinctUntilKeyChanged('type'),
    map(() => fromAgents.loadAgents()),
  );

export const saveAgent$: Epic = actions$ =>
  actions$.pipe(
    ofType(fromAgents.ActionType.SaveAgent),
    switchMap((action: ReturnType<typeof fromAgents.saveAgent>) => {
      return db.saveAgent(action.agent);
    }),
    map(() => fromAgents.agentSaved()),
  );

export const agentsEpic = combineEpics(loadAgents$, loadAgent$, saveAgent$);
