import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { collectionData } from 'rxfire/firestore';
import { IAgentEntity } from './entitys/agent';
import { ILeadEntity } from './entitys/lead';
import { IAgent } from './models/agent';
import { ILead } from './models/lead';

const firebaseConfig = {
  apiKey: 'AIzaSyBH4zZkEzBNBWqLXpiKgzOv1EaoN09ri1w',
  authDomain: 'twilio-app-ce96d.firebaseapp.com',
  databaseURL: 'https://twilio-app-ce96d.firebaseio.com',
  projectId: 'twilio-app-ce96d',
  storageBucket: 'twilio-app-ce96d.appspot.com',
  messagingSenderId: '1095927388847',
  appId: '1:1095927388847:web:3805cff4f29c1ab8fa925a',
  measurementId: 'G-D022ZKS71R',
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const firestore = app.firestore();

const getAgentsRef = () => firestore.collection('agents');
const getLeadsRef = () => firestore.collection('leads');
const getAgentRef = (agentId: string) => getAgentsRef().doc(agentId);

export const observeAgentsCollection: () => Observable<IAgent[]> = () => {
  const orderBy: keyof IAgentEntity = 'name';

  return collectionData<IAgentEntity & { id: string }>(
    getAgentsRef().orderBy(orderBy),
    'id',
  ).pipe(
    map(agentEntities => {
      const agents: IAgent[] = agentEntities.map(
        ({ id, name, email, phoneNumber, isActive, agentRoles }) => {
          const agent: IAgent = {
            id,
            name,
            email,
            phoneNumber,
            isActive,
            agentRoles,
          };

          return agent;
        },
      );

      return agents;
    }),
  );
};

export const observeLeadsCollection: () => Observable<ILead[]> = () => {
  const orderBy: keyof ILeadEntity = 'createdDt';
  return collectionData<ILeadEntity & { id: string }>(
    getLeadsRef().orderBy(orderBy, 'desc'),
    'id',
  ).pipe(
    map(agentEntities => {
      const agents: ILead[] = agentEntities.map(
        ({
          id,
          leadName,
          emails,
          leadType,
          leadAddress,
          contactPhoneNumber,
          contactPin,
          assignedAgent,
          agentAssignedOn,
          createdDt,
          updatedDt,
          status,
        }) => {
          const emailIds = emails.map(email => email.id);
          const assignedAgentId = assignedAgent?.id ?? null;

          const agent: ILead = {
            id,
            emailIds,
            leadType,
            leadName,
            leadAddress,
            contactPhoneNumber,
            contactPin,
            assignedAgentId,
            agentAssignedOn: agentAssignedOn?.toDate() ?? null,
            createdDt: createdDt?.toDate() ?? null,
            updatedDt: updatedDt?.toDate() ?? null,
            status,
          };

          return agent;
        },
      );

      return agents;
    }),
  );
};

const agentModelToEntity = (agent: IAgent): IAgentEntity => {
  const agentEntity: IAgentEntity = {
    name: agent.name,
    email: agent.email,
    phoneNumber: agent.phoneNumber,
    isActive: agent.isActive,
    agentRoles: agent.agentRoles,
  };

  return agentEntity;
};

export const saveAgent = async (agent: IAgent) => {
  const agentEntity: IAgentEntity = agentModelToEntity(agent);

  await (agent.id
    ? getAgentRef(agent.id).update(agentEntity)
    : getAgentsRef().add(agentEntity));
};
