import { createSelector } from 'reselect';

import { getRootState } from '../root-state';
import { IUser } from '../../models/user';

export interface IError {
  message: string;
  code: string;
}

export interface IAppState {
  loaded: boolean;
  user: null | IUser;
  lastError: IError | null;
}

enum ActionType {
  AddError = 'ADD_ERROR',
  UserProfileRefreshed = 'USER_PROFILE_REFRESHED',
  CreateUserProfile = 'CREATE_USER_PROFILE',
}

export function addError(
  error: IError,
): { type: ActionType.AddError; error: IError } {
  return {
    type: ActionType.AddError,
    error,
  };
}

export function userProfileRefreshed(
  user: IUser | null,
): { type: ActionType.UserProfileRefreshed; user: IUser | null } {
  return {
    type: ActionType.UserProfileRefreshed,
    user,
  };
}

export function signIn(): { type: ActionType.CreateUserProfile } {
  return {
    type: ActionType.CreateUserProfile,
  };
}

export type AppActions =
  | ReturnType<typeof addError>
  | ReturnType<typeof userProfileRefreshed>
  | ReturnType<typeof signIn>;

const app = (
  state: IAppState = {
    lastError: null,
    user: null,
    loaded: false,
  },
  action: AppActions,
): IAppState => {
  if ('error' in action) {
    return {
      ...state,
      lastError: action.error,
    };
  }

  switch (action.type) {
    case ActionType.UserProfileRefreshed: {
      return { ...state, user: action.user, loaded: true };
    }
    default: {
      return state;
    }
  }
};

export default app;

export const getAppState = createSelector(getRootState, state => state.app);

export const getLastError = createSelector(
  getAppState,
  state => state.lastError,
);

export const getErrorMessage = createSelector(getLastError, error =>
  error ? error.message || 'An unknown error occurred' : '',
);

export const getUser = createSelector(getAppState, state => state.user);
export const getAppLoaded = createSelector(getAppState, state => state.loaded);
