import { createSelector } from 'reselect';

import { IAppState } from './reducers/app';
import { IAgentsState } from './reducers/agents';
import { ILeadsState } from './reducers/leads';

export interface IRootState {
  app: IAppState;
  leads: ILeadsState;
  agents: IAgentsState;
}

export const getRootState = createSelector(
  (state: IRootState) => state,
  state => state,
);
