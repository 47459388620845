import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from '@ionic/react';
import React, { useState, useCallback, useEffect } from 'react';
import { useAuth } from '../hooks/auth';
import { useApp } from '../hooks/app';
import { useNav } from '../hooks/nav';

const Login: React.FC = () => {
  const title = 'Login';
  const { login } = useAuth();
  const { user } = useApp();
  const { go } = useNav();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const formSubmitted = useCallback(
    event => {
      event.preventDefault();
      login({ username, password });
    },
    [login, username, password],
  );

  const updatePassword = useCallback(
    event => {
      setPassword(event.detail.value);
    },
    [setPassword],
  );

  const updateUsername = useCallback(event => {
    setUsername(event.detail.value);
  }, []);

  useEffect(() => {
    if (user) {
      go('/');
    }
  }, [user, go]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={formSubmitted}>
          <IonList>
            <IonItem>
              <IonLabel>Email</IonLabel>
              <IonInput
                type="email"
                placeholder="Enter Email"
                onIonChange={updateUsername}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Password</IonLabel>
              <IonInput
                type="password"
                placeholder="Enter Password"
                onIonChange={updatePassword}
              ></IonInput>
            </IonItem>
          </IonList>
          <IonButton type="submit" expand="block">
            Login
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Login;
