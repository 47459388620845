import { createSelector } from 'reselect';

import { getRootState } from '../root-state';
import { ILead } from '../../models/lead';
import { getAgentsObj } from './agents';

export interface ILeadsState {
  leads: {
    [key: string]: ILead;
  };
  selectedLeadId: string | null;
}

export enum ActionType {
  LoadLeads = 'LOAD_LEADS',
  LoadLead = 'LOAD_LEAD',
  LeadsLoaded = 'LEADS_LOADED',
}

export function loadLeads(): { type: ActionType.LoadLeads } {
  return {
    type: ActionType.LoadLeads,
  };
}

export function loadLead(
  leadId: string | null,
): { type: ActionType.LoadLead; leadId: string | null } {
  return {
    leadId,
    type: ActionType.LoadLead,
  };
}

export function leadsLoaded(
  leads: ILead[],
): { type: ActionType.LeadsLoaded; leads: ILead[] } {
  return {
    leads,
    type: ActionType.LeadsLoaded,
  };
}

export type AppActions =
  | ReturnType<typeof loadLeads>
  | ReturnType<typeof loadLead>
  | ReturnType<typeof leadsLoaded>;

const app = (
  state: ILeadsState = {
    leads: {},
    selectedLeadId: null,
  },
  action: AppActions,
): ILeadsState => {
  switch (action.type) {
    case ActionType.LeadsLoaded: {
      return {
        ...state,
        leads: action.leads.reduce((acc, lead) => {
          return { ...acc, [lead.id!]: lead };
        }, {}),
      };
    }
    case ActionType.LoadLead: {
      return {
        ...state,
        selectedLeadId: action.leadId,
      };
    }
    default:
      return state;
  }
};

export default app;

export const getLeadsState = createSelector(getRootState, state => state.leads);

export const getLeadsObj = createSelector(getLeadsState, state => state.leads);

export const getLeads = createSelector(getLeadsObj, leads =>
  Object.values(leads),
);

export const getSelectedLeadId = createSelector(
  getLeadsState,
  state => state.selectedLeadId,
);

export const getSelectedLead = createSelector(
  getAgentsObj,
  getLeadsObj,
  getSelectedLeadId,
  (agents, leads, selectedLeadId) => {
    const lead = selectedLeadId ? leads[selectedLeadId] ?? null : null;

    if (lead) {
      const assignedAgentName = lead.assignedAgentId
        ? agents[lead.assignedAgentId]?.name ?? null
        : null;

      return {
        ...lead,
        assignedAgentName,
      };
    }

    return lead;
  },
);
