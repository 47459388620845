import React, { useCallback, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonList,
  IonLabel,
} from '@ionic/react';

import { useLeads } from '../hooks/leads';

import './Leads.css';
import { useNav } from '../hooks/nav';

const Leads: React.FC = () => {
  const { loadLeads, leads } = useLeads();
  const { go } = useNav();

  useEffect(() => {
    loadLeads();
  }, [loadLeads]);

  const goToLeadDetail = useCallback(
    (id: string) => {
      return () => {
        go(`/leads/${id}`);
      };
    },
    [go],
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Leads</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Leads</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {leads.map(({ id, leadName }) => {
            return (
              <IonItem key={id} detail button onClick={goToLeadDetail(id!)}>
                <IonLabel>{leadName}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Leads;
