import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';

import * as fromLeads from '../store/reducers/leads';

export const useLeads = () => {
  const dispatch = useDispatch();
  const leads = useSelector(fromLeads.getLeads);
  const selectedLead = useSelector(fromLeads.getSelectedLead);

  const loadLeads = useCallback(() => {
    dispatch(fromLeads.loadLeads());
  }, [dispatch]);

  const loadLeadDetail = useCallback(
    (id: string | null) => {
      dispatch(fromLeads.loadLead(id));
    },
    [dispatch],
  );

  return {
    leads,
    loadLeads,
    loadLeadDetail,
    selectedLead,
  };
};
