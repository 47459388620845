import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useRef, useEffect, useState } from 'react';

import * as fromAgents from '../store/reducers/agents';
import { IAgent } from '../models/agent';

enum SaveState {
  Inital = 'inital',
  Saving = 'saving',
  Saved = 'saved',
}

export const useAgents = () => {
  const dispatch = useDispatch();
  const agents = useSelector(fromAgents.getAgents);
  const selectedAgent = useSelector(fromAgents.getSelectedAgent);
  const isSaving = useSelector(fromAgents.getIsSaving);
  const saveState = useRef<SaveState>(SaveState.Inital);
  const [hasSaved, setHasSaved] = useState(false);

  const loadAgents = useCallback(() => {
    dispatch(fromAgents.loadAgents());
  }, [dispatch]);

  const loadAgentDetail = useCallback(
    (id: string | null) => {
      dispatch(fromAgents.loadAgent(id));
    },
    [dispatch],
  );

  const updateAgent = useCallback(
    (agent: IAgent) => {
      dispatch(fromAgents.saveAgent(agent));
    },
    [dispatch],
  );

  useEffect(() => {
    if (isSaving) {
      saveState.current = SaveState.Saving;
    } else if (saveState.current === SaveState.Saving && !isSaving) {
      saveState.current = SaveState.Saved;
      setHasSaved(true);

      setTimeout(() => {
        saveState.current = SaveState.Inital;
        setHasSaved(false);
      }, 250);
    }
  }, [isSaving, saveState]);

  return {
    agents,
    loadAgents,
    loadAgentDetail,
    selectedAgent,
    updateAgent,
    isSaving,
    hasSaved,
  };
};
